import React, { Component } from "react";
import styled from "styled-components";
import { ACSS, H4, H5CSS, H6 } from "@upsolve/ui";
import { format } from "date-fns";
import { get } from "lodash";
import { graphql } from "gatsby";
import { theme } from "@upsolve/ui";
import { EVENTS } from "@upsolve/shared";
import { track } from "../components/analytics/track";
import * as CONTENT_CREATION_METHODS from "../components/analytics/contentCreationMethods";
import * as CONTENT_GROUPS from "../components/analytics/contentGroups";
import CTASideBySideOfferings from "../components/CTA/CTASideBySideOfferings";
import MainFooter from "../components/Layout/Footer";
import MainHeader from "../components/Layout/Header";
import Page from "../components/Layout/Page.div";
import SEO from "../components/SEO/SEO";
import TopLevelLayout from "../components/Layout/TopLevelLayout";
import { Label } from "../components/Type/Label.label";
import { CTATarget } from "../components/CTA/getCTAData";

const contentGroup = CONTENT_GROUPS.FRESH_START_DIARY;
const templateProps = {
  pageComponentName: "FreshStartDiary",
  pageComponentVersion: 0,
};
const trackingPageContext = {
  contentCreationMethod: CONTENT_CREATION_METHODS.WRITTEN,
  contentGroup,
  ...templateProps,
};

export default class FreshStartDiary extends Component {

  componentDidMount() {
    track(EVENTS.PAGE_VIEW, { pageContext: trackingPageContext });
  }

  render() {
    const contentNode = this.props.data.contentfulFreshStartDiary;
    const { content, slug, title, caseFiled, filerImage, storyQuote, publishedAt } = contentNode;
    const cta = "Get Your Fresh Start";

    return (
      <TopLevelLayout persistentCta={cta}>
        <SEO
          contentPath={`/fresh-starts/${slug}`}
          contentNode={contentNode}
          jsonLD={[
            {
              "@context": "http://schema.org",
              "@type": "BreadcrumbList",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Fresh Starts",
                  item: "https://upsolve.org/fresh-starts/",
                },
                {
                  "@type": "ListItem",
                  position: 2,
                  name: title,
                  item: `https://upsolve.org/fresh-starts/${slug}`,
                },
              ],
            },
            {
              "@context": "https://schema.org",
              "@type": "Article",
              mainEntityOfPage: {
                "@type": "WebPage",
                "@id": `https://upsolve.org/fresh-starts/${slug}`,
              },
              headline: title,
              image: get(contentNode, "seoImage.image.file.url"),
              datePublished: new Date(publishedAt).toISOString(),
              author: {
                "@type": "Organization",
                name: "Upsolve",
              },
              publisher: {
                "@type": "Organization",
                name: "Upsolve",
                logo: {
                  "@type": "ImageObject",
                  height: 60,
                  url: "http://static.upsolve.org/logos/upsolve-icon-60.png",
                  width: 60,
                },
              },
              description: get(storyQuote, "childMarkdownRemark.rawMarkdownBody"),
            },
          ]}
        />
        <MainHeader trackingPageContext={trackingPageContext} />
        <DiaryWrapper>
          <DiaryPage>
            <div className="hero">
              <div className="intro__text">
                <div>
                  <Label>Fresh Start Diary</Label>
                  <H4 as="h1">{title}</H4>
                  <H6 as="h2">{get(storyQuote, "childMarkdownRemark.rawMarkdownBody")}</H6>
                </div>
                <div className="results">&mdash; Filed {format(new Date(caseFiled), "M/d/yy")}</div>
              </div>
              <div className="intro__picture">
                <img alt={get(filerImage, "altText")} src={get(filerImage, "image.file.url")} />
              </div>
            </div>
            <MarkdownWrapper
              className="content"
              dangerouslySetInnerHTML={{ __html: content.childMarkdownRemark.html }}
            />
          </DiaryPage>
        </DiaryWrapper>
        <CTASideBySideOfferings trackingPageContext={trackingPageContext} ctaTarget={CTATarget.SCREENER} />
        <MainFooter />
      </TopLevelLayout>
    );
  }
}

export const pageQuery = graphql`
  query FreshStartDiaryBySlug($slug: String!) {
    contentfulFreshStartDiary(slug: { eq: $slug }) {
      id
      publishedAt
      slug
      title
      filerName
      filerImage {
        id
        altText
        image {
          file {
            url
          }
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      storyTags
      storyQuote {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      caseFiled
      seoTitle
      seoDescription
      seoImage {
        image {
          file {
            url
          }
        }
      }
    }
  }
`;

export const DiaryWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  @media (max-width: 45em) {
    flex-direction: column;
  }
`;

export const MarkdownWrapper = styled.article`
  h1,
  h2,
  h3,
  h4 {
    margin: 1em 0;
  }
  h5 {
    ${H5CSS}
  }
  h5:not(:first-of-type) {
    margin-top: 3em;
  }
  ul {
    list-style-type: disc;
  }
  ul,
  ol {
    padding: 0 1em 1em 1em;
  }
  li {
    margin: 0 0.25em 0.5em;
  }
  a {
    ${ACSS}
  }
  hr {
    margin: 1em 0;
    border: 1px solid ${theme.colors.white["700"]};
  }
  img {
    max-width: 100%;
  }
`;

export const DiaryPage = styled(Page)`
  width: 100%;
  padding: 4em;
  @media screen and (max-width: 45em) {
    padding: 4em 2em;
  }
  .hero {
    display: flex;
    .intro__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 3;
      margin-right: 2em;
      margin-bottom: 1em;
      h1 {
        padding: 0.5em 0 0.25em;
      }
      h2 {
        padding: 0.25em 0 0.5em;
      }
      .results {
        font-size: 14px;
        letter-spacing: 0.5px;
      }
    }
    .intro__picture {
      flex-grow: 1;
      min-width: 240px;
      max-height: 360px;
      overflow-y: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        padding: 0.5em 0 2em;
        max-width: 300px;
        max-height: 450px;
      }
    }
    @media (max-width: 45em) {
      flex-direction: column;
      text-align: center;
      .intro__text {
        margin-right: 0;
      }
      .intro__picture {
        img {
          max-width: 80%;
        }
      }
    }
  }
  .content {
    margin: 0;
  }
`;
